import * as React from "react"
import '../../../index.scss';
//import './sites/all/themes/tickets/js/estacas/form_tickets_rd.js';
import '../../../sites/all/themes/tickets/js/estacas/map.js';
import '../../../sites/all/themes/tickets/js/estacas/cards_rd'
//import './sites/all/themes/tickets/js/estacas/stickyfloat'
import '../../../sites/all/themes/tickets/js/estacas/eventos'
import '../../../sites/all/themes/tickets/js/estacas/form_tickets_rd'
//import './stylesheets/tickets.css'
//import './sites/all/themes/tickets/js/estacas/header_rd';
import '../../../sites/all/themes/tickets/js/estacas/instagram_posts';
import '../../../sites/all/themes/tickets/js/estacas/header_rd';
import '../../../sites/all/themes/tickets/js/_analyticstagmanager';

import { Helmet } from "react-helmet"
import * as data from '../../../../static/drupal/dummy-quinta-margarita'
import PlantillaHospedaje from "../../../layout/plantillaHospedaje";
// markup
const Node = () => {
    return (
        <body className={'html front not-logged-in no-sidebars page-node page-node- page-node-1612 node-type-page lang-es'}>
            <Helmet
                htmlAttributes={{
                    lang: 'en',
                }}

                meta={[
                    {
                        name: 'description',
                        content: data.dummyQuintaMargarita.descripcion
                        ,
                    },
                    {
                        property: `og:title`,
                        content: data.dummyQuintaMargarita.title,
                    },
                    {
                        property: 'og:description',
                        content: data.dummyQuintaMargarita.descripcion,
                    },
                    {
                        property: 'og:url',
                        content: data.dummyQuintaMargarita.url,
                    },
                    {
                        property: 'og:image',
                        content: data.dummyQuintaMargarita.image,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },

                ]}
            />
            <PlantillaHospedaje />

        </body>
    )
}

export default Node
